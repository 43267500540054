/* global */

@font-face {
  font-family: 'Heavitas';
  src:  url("./fonts/Heavitas.ttf") format('truetype')
}

:root {
  --header-height: 90px;
  --header-margin: 50px;
  --magic-green: #43C83A;
  --magic-darkgreen: rgba(35, 158, 44, 1);
  --magic-darkdarkgreen: rgb(4, 92, 10);
  --magic-gradient: linear-gradient(90deg, #43C83A 0%, rgba(35, 158, 44, 1) 100%);
}

#root {
  display: flex;
  flex-direction: column;
  /* position: relative !important; */
}

body {
  background: rgb(20, 20, 20);
  color: white
}

.App {
  text-align: center;
}

.title {
  font-family: Heavitas;
  font-size: 2.2em;
  margin: auto;
  margin-block: 0px;
}

.sub-title {
  font-family: Heavitas;
  font-size: 1.5em;
  margin: auto;
  text-align: center;
}


.title-text {
  margin: auto;
  font-size: 3em;
  align-self: center;
  max-width: 100%;


}

button {
  cursor: pointer;
  border: none;
  font-weight: 600;
  font-size: 0.8em;
  color: white;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--magic-green);
}

button:disabled {
  /* cursor: not-allowed; */
  /* background-color: rgb(92, 92, 92); */
}



.button-cursor {
  cursor: pointer;
}

.button {
  cursor: pointer;
  border: none;
  font-weight: 600;
  font-size: 0.8em;
  color: white;
  padding: 10px;
  border-radius: 5px;
  background-color: #202020;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  margin-left: 3px
}


#content {
  height: calc(100vh - 80px);
  display: flex;
}

main {
  margin: var(--header-height) 0 0 0;
  padding: 20px
}

.no-sidenav {
  margin: 80px 0 0 0px;
}

.hyperlink {
  text-decoration: underline;
  cursor: pointer;
  text-decoration-color: var(--magic-green);
  color: var(--magic-green);
}

.classic-hyperlink {
  text-decoration: none;
  cursor: pointer;
  color: inherit
}

.gradient-text {
  background: var(--magic-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2em;
  font-family: heavitas;
}



.line {
  height: 0.1px;
  width: 60px;
}

.input {
  display: flex;
  height: 40px;
  max-width: 100%;
  width: 300px;
  min-width: 0px;
  justify-content: center;
  padding-inline: 10px;
  border: none;
  background-color: #f0f0f0;
  box-sizing: border-box;
  border-radius: 5px;
}

.home-title-text {
  font-size: 3em;
  margin-top: 0px;

}


strong {
  font-size: 1.5em;
  font-family: heavitas;
  font-weight: bold;
}

/* span {
  text-align: center;
} */

.label-detail {
  text-align: right;
}

.new-margin {
  margin-bottom: 20px !important;
}

.new-big-margin {
  margin-bottom: 40px !important;
}

.label {
  display: inline-block;
  min-width: 150px;



}

.title-label {
  font-weight: 600;

}

.dropdown {
  border: none !important;
  height: 40px !important;
  background-color: #f0f0f0 !important;
  border-radius: 5px !important;
}

.dropdown-menu {
  border: none !important;
  z-index: 9 !important;
  background-color: #f0f0f0 !important;
  border-radius: 5px !important;
}

.shadow-bottom {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.shadow {
  box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.2);
}

.reversed-row {
  flex-direction: row-reverse !important;
}

.green-shadow {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #202020;
  max-width: 600;
  padding: 20;
  border-radius: 15px;
  margin: auto;
  -webkit-box-shadow: 13px 13px 0px 0px var(--magic-gradient);
  box-shadow: 13px 13px 0px 0px var(--magic-gradient);
}

/* .green-shadow::after {
  content: "";

  position: absolute;
  width: 100%;
  height: 100%;
  top: 15px;
  left: 15px;
  border-radius: 15px;
  margin: auto;
  z-index: -1;
  background: var(--magic-gradient);

} */

.none {
  display: none;
}

.chevron-rotate-down {
  animation: rotation 0.4s;


}

.chevron-rotate-up {
  animation: rotation 0.4s;

}


/* home */

.home-know-more-button {
  cursor: pointer;
}

.home-title-text-container {
  max-width: 600px;
  text-align: center;
}



.home-title-img {
  height: auto;
  width: 35%;
  aspect: ratio 1px / 1px;
}

.home-title-container {
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;

}

.home-title-text {
  font-size: 3em;
  margin-top: 0px;
}

/* header */

header {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: var(--header-height);
  width: 100vw;
  background: #202020;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  overflow-y: hidden;
  /* Disable horizontal scroll */
  margin: 0px;
  padding: 0px
}

.nav-header {
  max-width: 1200px;
  width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: var(--header-margin);

}



.nav-button {
  cursor: pointer;
  margin: 8px 0px 8px 60px;
  color: white;
  font-size: 1em;
  font-weight: bold;
  transition: 0.3s;
  flex-wrap: wrap;
}

.nav-button:hover {
  color: rgb(155, 155, 155)
}

.nav-menu {


  color: white;
}

.nav-icon {
  height: calc(var(--header-height) * 0.65);
  margin-top: 6px;
}

.nav-mobile-opened {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #202020;
  padding-top: 80px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100% - 80px);
  z-index: 9;
  overflow-y: scroll;
  animation: appearFromRight 0.5s;
}


.nav-mobile-closed {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #202020;
  padding-top: 80px;
  height: calc(100% - 80px);
  top: 0;
  left: 100%;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  /* display: none; */
  animation: disappearToRight 0.5s;
}

.nav-mobile {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #202020;
  padding-top: 80px;
  height: calc(100% - 80px);
  top: 0;
  left: 100%;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: none;
 
}

.nav-mobile-button {
  cursor: pointer;
  margin: 30px 0px 30px 0px;
  color: white;
  font-size: 1em;
  font-weight: bold;
  transition: 0.3s;
}

.nav-mobile-button:hover {
  color: rgb(155, 155, 155)
}


/* login */

.login-input {
  width: 85%;

  margin-bottom: 20px;





}

*::-webkit-scrollbar {
  width: 10px;

}



*::-webkit-scrollbar-thumb {
  background-color: white;
  /* color of the scroll thumb */
  border-radius: 10px;

  /* roundness of the scroll thumb */
  /* border: 1px solid #000066;  */
  /* creates padding around scroll thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(207, 207, 207);
  /* color of the scroll thumb */


  /* roundness of the scroll thumb */
  /* border: 1px solid #000066;  */
  /* creates padding around scroll thumb */
}


/* vending machines */

.vending-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.vending-text {
  max-width: 750px;
  width: 100%;
  margin: auto;
}

.vending-image-text-container {
  max-width: 750px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.vending-image-text-container-text {

  display: flex;
  flex-direction: column;

}

.vending-image-img {
  height: auto;
  width: 20%;
  aspect: ratio 1px / 1px;
}

.vending-image-mball {
  height: auto;
  width: 25%;
  aspect: ratio 1px / 1px;
}

.vending-image-mmball {
  height: auto;
  width: 26%;
  aspect: ratio 1px / 1px;
}




.vending-title {
  max-width: 750px;
  width: 100%;
  margin: auto;
  font-size: 2em;
  font-weight: bold;
}

.vending-background-title {
  padding-block-start: 13px;
  padding-block-end: 10px;
  background: var(--magic-gradient);
  width: calc(100vw - 10px);
  text-align: center;
}



/* points of sale */

.pos-container-container {
  display: flex;
  flex-direction: column;
  background-color: #202020;
  max-width: 900px;
  height: 400px;
  padding: 20px;
  border-radius: 15px;
  margin: auto
}

.pos-container {
  display: flex;
  flex-direction: row;
}

.pos-locations-container {
  max-width: 400px;
  width: 400px;
  height: 400px;
  overflow-y: scroll;
  margin-right: 5px;
}

.pos-location-container {

  display: flex;
  flex-direction: column;

  border-radius: 15px;

  width: 100%;

}




/* varieties */

.varieties-title-container {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #202020;
  border-radius: 10px;
  padding: 20px;
  /* cursor: pointer; */

  z-index: 10;
}


.varieties-type {
  display: flex;
  flex-direction: column;
  background-color: #202020;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  transform: translate(0, -10px);

  box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;

}

.varieties-title-img-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.varieties-img-container {
  display: flex;
  flex-direction: row;
  width: 75px;
  height: 75px;
  align-items: center;
}

.varieties-variety {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.varieties-type-title {
  margin-block: 20px;
  font-weight: bold;
}

/* whats cbd */






.cbd-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.cbd-text {
  max-width: 1050px;
  width: 100%;
  margin: auto;


}

.cbd-title {
  max-width: 1050px;
  width: 100%;
  margin: auto;
  font-size: 2em;
  font-weight: bold;
}



.invisible {
  visibility: hidden;
}






/* footer */



footer {
  margin-top: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.footer-section {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-inline: 5%;
}

.footer-menu {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  padding-block: 10px;
  background: var(--magic-gradient);

}

.footer-title {
  margin-block: 10px;
  font-size: 1.3em;
  padding-bottom: 0px;
  font-weight: bold;

}

.footer-subtitle {
  margin:0px;
  margin-block: 10px;
  font-size: 1.0em;
  max-width: 220px;
}

.footer-social {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-social-img-first {
  filter: invert(64%) sepia(0%) saturate(854%) hue-rotate(200deg) brightness(98%) contrast(84%);
  margin-right: 10px;
}

.footer-social-img {
  filter: invert(64%) sepia(0%) saturate(854%) hue-rotate(200deg) brightness(98%) contrast(84%);

}

.footer-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: rgb(20, 20, 20);
}

.footer-year {
  font-weight: bold;
  padding-inline: 20px;
}


.footer-icon {
  height: calc(var(--header-height) * 0.30);
 
  padding-inline: 20px;
}


/* contact form */


.franchise-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.franchise-text {
  max-width: 1050px;
  width: 100%;
  margin: auto;

}

.franchise-title {
  max-width: 1050px;
  width: 100%;
  margin: auto;
  font-size: 2em;
  font-weight: bold;
}

.franchise-subtitle {
  max-width: 1050px;
  width: 100%;
  margin: auto;
  font-size: 1.6em;
  font-weight: bold;
}

.franchise-contact-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  max-width: 600px;
}


.franchise-contact-text {
  margin: 0px;
  font-weight: 500;
}


.contact-form {
  padding: 50px;
  background-color: #202020;
  border-radius: 20px;
  max-width: 500px;
}

.contact-form-container {
  display: flex;
  flex-direction: row;
}

.contact-form-inputs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 25px;
  width: 100%;
}

.contact-form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;


}

.contact-form-last-input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;


}

.contact-form-input {
  border-radius: 5px;
  height: 35px;
  outline-color: var(--magic-darkgreen);

}


.contact-form-textarea {
  border-radius: 5px;
  height: 100px;
  resize: none;
  font-family: inherit;
  outline-color: var(--magic-darkgreen);
}

.contact-form-textarea-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.contact-form-textarea-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}



.contact-form-sendbtn {
  border-radius: 5px;
  height: 35px;
  background-color: var(--magic-green);
  transition: 0.3s;
  padding: 0px;
}



.contact-form-sendbtn:hover:enabled {
  background-color: var(--magic-darkgreen);
}

.contact-form-messagesent {
  position: absolute;
  left: 50%;
  white-space: nowrap;
  bottom: 0px;
  transform: translate(-50%, -15px);
  text-align: center;
  animation: opacityFade 1s
}

.contact-form-messageerror {
  position: absolute;
  left: 50%;
  white-space: nowrap;
  bottom: 0px;
  transform: translate(-50%, -15px);
  text-align: center;
  color: red;
  animation: opacityFade 1s;

}





@media (max-width:800px) {
  .title-text {
    font-size: 2.5em;
  }

  @media (max-width:400px) {
    .title-text {
      font-size: 1.5em;
    }
  }

  .pos-container {
    flex-direction: column;
  }



  .pos-locations-container {
    max-width: 100%;
    width: 100%;
    height: 400px;
    margin-bottom: 10px;
    margin-right: 0px;
  }



  .pos-container-container {
    height: auto;
  }

  .reversed-row {
    flex-direction: column !important;
  }

  .vending-image-text-container {

    flex-direction: column;
  }

  .vending-image-img {
    width: 150px;
    margin-bottom: 20px;
  }

  .vending-image-mball {
    width: 200px;
    margin-bottom: 20px;

  }

  .vending-image-mmball {
    width: 200px;
    margin-bottom: 20px;
  }

  .vending-image-text-container-text {

    margin: auto
  }



  .contact-form {

    max-width: 300px;
  }

  .contact-form-container {

    flex-direction: column;
  }

  .contact-form-last-input-container {

    margin-bottom: 10px;

  }

  .contact-form-textarea-container {

    margin-bottom: 30px;
  }

  .home-title-text-container {
    max-width: 500px;
  }

  .home-title-img {
    width: 300px;
  }

  .home-title-container {
    flex-direction: column;
  }

  .footer-menu {

    flex-direction: column;
    text-align: center;

  }

  .footer-section {
    align-items: center;
    margin-block: 10px;
  }



}



@media (max-width:1000px) and (min-width:801px) {

  .home-title-text-container {
    max-width: 450px;

  }

  .home-title-text {
    font-size: 2em;
  }




}


@media (max-width:1100px) {


  .nav-button {

    font-size: 0.85em;
    margin-left: 25px;
  }



}

@media (min-width: 801px) {}

@keyframes heightOpen {
  from {
    transform: wi
  }

  to {
    opacity: 1
  }
}

@keyframes opacityFade {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes appearFromRight {
  from {
   
    transform: translateX(100%);
  }

  to {
   
    transform: translateX(0px);
  }
}

@keyframes disappearToRight {
  0% {
  
    transform: translateX(-100%);
  }

  100% {
   
    transform: translateX(0%);
    
  }
}

@keyframes rotation {
  from {
    transform: rotate(180deg);

  }

  to {
    transform: rotate(0deg);

  }
}

